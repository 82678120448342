<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)" v-if="report">
      <div class="row">
        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="title"
            rules="required"
          >
            <label for="title" class="form-label">Title</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="title"
              v-model="form.Title"
              placeholder="Title"
            />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="description"
            rules="required"
          >
            <label for="description" class="form-label"
              >Description/Abstract</label
            >
            <textarea
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="description"
              v-model="form.Description"
            ></textarea>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="col-12 mb-3">
          <label for="image" class="form-label">Image</label>
          <div
            class="d-flex align-items-center justify-content-between flex-row"
          >
            <div class="d-flex flex-row">
              <input
                type="file"
                ref="file"
                class="form-control me-3"
                id="image"
                accept="image/png,image/jpeg,image/gif,image/jpg"
                @change="processFile('image')"
              />
              <label class="label">{{ imageName }}</label>
            </div>

            <div class="p-2">
              <a
                :href="imageUrl"
                target="_blank"
                class="btn btn-secondary btn-sm"
                v-if="imageUrl"
              >
                <i class="bi bi-card-image"></i>Preview
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="file" class="form-label">File (*.pdf)</label>
          <div
            class="d-flex flex-row align-items-center justify-content-between"
          >
            <div class="d-flex flex-row">
              <input
                type="file"
                ref="fileUpload"
                class="form-control me-3"
                id="file"
                accept=".pdf"
                @change="processFile('file')"
              />
              <label
                class="label"
                v-if="fileUrl || fileuploadName !== 'No file chosen'"
                >{{ fileuploadName }}</label
              >
              <label class="label" v-else>No file chosen</label>
            </div>
            <div class="p-2">
              <a
                :href="fileUrl"
                target="_blank"
                class="btn btn-secondary btn-sm"
                v-if="fileUrl"
              >
                <i class="bi bi-file-earmark"></i>Preview
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="keywords"
            rules=""
          >
            <label for="keywords" class="form-label">Keywords</label>
            <input
              type="text"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
              id="keywords"
              v-model="form.keywords"
              placeholder="Keywords"
            />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="col-12 mb-3">
          <validation-provider
            v-slot="{ errors, pristine }"
            name="reported_by"
            rules=""
          >
            <label for="reported_by" class="form-label">Reported By</label>
            <select
              v-model="form.Reported_by"
              id="reported_by"
              class="form-control"
              :class="{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              }"
            >
              <option value="" disabled selected>Select User</option>
              <option
                v-for="user in options.users"
                :key="user.id"
                :value="user.email"
              >
                {{ user.profile ? user.profile.fullname : user.username }} ({{
                  user.email
                }})
              </option>
            </select>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
      </div>
      <button
        v-if="!saving"
        type="submit"
        class="btn btn-primary px-2 btn-sm me-3"
      >
        Update
      </button>
      <button
        v-else
        class="btn btn-primary px-2 btn-sm me-3"
        type="button"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>

      <button
        type="button"
        class="btn btn-danger btn-sm px-2"
        @click.prevent="cancelUpdate"
      >
        Cancel
      </button>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "StudyReportsForm",
  props: ["report"],
  data() {
    return {
      saving: false,
      loading: true,
      form: {
        Title: "",
        Description: "",
        Image: null,
        fileupload: null,
        keywords: "",
        Reported_by: "",
      },
      options: {
        users: [],
      },

      fileuploadName: "No file chosen",
      imageName: "No file chosen",
      imageUrl: "",
      fileUrl: "",
    };
  },
  watch: {
    report() {
      this.imageUrl = this.report.Image;
      this.fileUrl = this.report.fileupload;
      this.form = Object.assign({}, this.report);
      for (let key in this.form) {
        if (this.form[key] === "null") {
          this.form[key] = null;
        }
      }
      this.createFile(this.report.Image);
      this.createFile(this.report.fileupload);
    },
  },
  mounted() {
    this.getSelectionData();
    this.loading = false;
  },
  methods: {
    async createFile(fileurl) {
      if (fileurl) {
        //fileurl = fileurl + ""; //returns string
        const splittedUrl = fileurl.split("/");
        let filename = splittedUrl[splittedUrl.length - 1];
        filename =
          filename.split(".")[0].substr(0, 30) + "__." + filename.split(".")[1];
        let response = await fetch(fileurl);

        let data = await response.blob();

        let metadata = {
          type: data.type,
        };
        let file = new File([data], `${filename}`, metadata);
        if (data.type.split("/")[0] === "image") {
          this.form.Image = file;
          this.imageName = filename;
        } else {
          this.form.fileupload = file;
          this.fileuploadName = filename;
        }
      }
    },
    cancelUpdate() {
      //this.resetForm();
      this.$emit("cancel");
    },
    async getSelectionData() {
      await this.$repository.user.get().then((res) => {
        this.options.users = res.data;
      });
    },
    processFile(type) {
      if (type === "image") {
        this.form.Image = this.$refs.file.files[0];
        this.imageName =
          this.$refs.file.files[0].name.split(".")[0].substr(0, 30) +
          "." +
          this.$refs.file.files[0].name.split(".")[1];
      } else {
        this.form.fileupload = this.$refs.fileUpload.files[0];
        this.fileuploadName =
          this.$refs.fileUpload.files[0].name.split(".")[0].substr(0, 30) +
          "." +
          this.$refs.fileUpload.files[0].name.split(".")[1];
      }
    },
    resetForm() {
      this.form = {
        Title: "",
        Description: "",
        Image: "",
        fileupload: "",
        keywords: "",
        Reported_by: "",
      };
      this.fileuploadName = "No file chosen";
      this.imageName = "No file chosen";
      this.imageUrl = "";
      this.fileUrl = "";
      this.$refs.file.value = null;
      this.$refs.fileUpload.value = null;
      this.$refs.observer.reset();
    },
    submit() {
      this.saving = true;
      let data = new FormData();

      for (let formKey in this.form) {
        data.append(formKey, this.form[formKey] ? this.form[formKey] : "");
      }

      this.$repository.studyReports
        .edit(data, this.report.id)
        .then(() => {
          this.$toast.success("Reports Updated");
          this.resetForm();
          this.$emit("updated");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$toast.warning(
              "You are not authorized. Please login and try again."
            );
          }
        })
        .finally(() => {
          this.saving = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.label {
  min-width: max-content;
}
input[type="file"] {
  width: 100px;
  overflow: hidden;
}
</style>
