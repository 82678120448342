var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"title"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Title),expression:"form.Title"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"title","placeholder":"Title"},domProps:{"value":(_vm.form.Title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Title", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"description"}},[_vm._v("Description/Abstract")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Description),expression:"form.Description"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"description"},domProps:{"value":(_vm.form.Description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
            var validate = ref.validate;
return [_c('label',{staticClass:"form-label",attrs:{"for":"image"}},[_vm._v("Image")]),_c('input',{ref:"file",staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"accept":"image/png,image/jpeg,image/gif,image/jpg","type":"file","id":"image"},on:{"input":validate,"change":function($event){return _vm.processFile('image')}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"file"}},[_vm._v("File (*.pdf)")]),_c('input',{ref:"fileUpload",staticClass:"form-control",attrs:{"type":"file","id":"file","accept":".pdf"},on:{"change":function($event){return _vm.processFile('file')}}})]),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"keywords","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"keywords"}},[_vm._v("Keywords")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.keywords),expression:"form.keywords"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"keywords","placeholder":"Keywords"},domProps:{"value":(_vm.form.keywords)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "keywords", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"reported_by","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"reported_by"}},[_vm._v("Reported By")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Reported_by),expression:"form.Reported_by"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"id":"reported_by"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Reported_by", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select User")]),_vm._l((_vm.options.users),function(user){return _c('option',{key:user.id,domProps:{"value":user.email}},[_vm._v(" "+_vm._s(user.profile ? user.profile.fullname : user.username)+" ("+_vm._s(user.email)+") ")])})],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),(!_vm.saving)?_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"submit"}},[_vm._v(" Save ")]):_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")]),_vm._t("cancel")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }