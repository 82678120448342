<template>
  <div class="card mb-4">
    <router-link :to="{ name: 'view-study-report', params: { id: id } }">
      <img :src="imageSrc" class="card-img-top" :alt="title" />
      <div class="card-body topCardBody">
        <router-link
          :to="{ name: 'view-study-report', params: { id: id } }"
          class="fs-6 card-title fw-bold text-blue"
        >
          {{ title }}
        </router-link>
        <p class="card-text text-dark">{{ description.substr(0, 50) }}...</p>
      </div>
    </router-link>
    <div class="card-body buttons">
      <div class="d-flex flex-row justify-content-between align-items-center ">
        <router-link
          :to="{ name: 'view-study-report', params: { id: id } }"
          class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 addnewbtn allbtn me-4 button"
        >
          <span class="d-flex align-items-center"
            ><span class="small">Read More</span></span
          >
        </router-link>
        <div v-if="$store.state.auth.isLoggedIn && file">
          <button
            @click="downloadImage"
            class="btn btn-sm btn-danger rounded-0 button"
            v-if="!downloading"
          >
            Download Pdf
            <i class="bi bi-download"></i>
          </button>
          <button
            v-else
            class="btn btn-danger button px-2 btn-sm me-3"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Downloading...
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import download from "downloadjs";
export default {
  name: "StudyReportCard",
  props: {
    id: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    imageSrc: {
      required: true,
      type: String,
      default: "@/assets/img/map4.png",
    },
    file: {
      required: true,
    },
  },
  data() {
    return {
      downloading: false,
    };
  },
  methods: {
    downloadImage() {
      this.downloading = true;
      download(this.file);
      setTimeout(() => {
        this.downloading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.card {
  height: 350px;
  position: relative;
}
.card img img {
  height: 50%;
}
.button {
  min-width: max-content;
}
.buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: auto;
}
.card-body.topCardBody p {
    margin: 0px 0px 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
